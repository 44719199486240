import { InfoCircleOutlined, NotificationOutlined } from "@ant-design/icons";
import {
  googleLogout,
  useGoogleLogin
} from "@react-oauth/google";
import { Button, notification } from "antd";
import { SheetsScopes } from "app/models/google-oauth/SheetsScopes";
import { projectSelector } from "app/redux/slides/project.slide";
import projectServices from "app/services/project.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GoogleSheetIcon from "../SVGs/GoogleSheetIcon";
import "./button.module.scss";

function ExportSheetButton(props: any) {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const { data } = useSelector(projectSelector);
  const [loading, setLoading] = useState(false);
  const exportGoogleSheets = async (googleResponse: any) => {
    setLoading(true);
    try {
      const url: string = await projectServices.exportToGoogleSheets(data.id, googleResponse);
      api.info({
        icon: <InfoCircleOutlined />,
        message: `Export finished`,
        description: <a href={url.replace(" ", "")} target="_blank" rel="noreferrer" >Click here to open</a>,
      });
    } catch (error) {}
    setLoading(false);
  };
  const login = useGoogleLogin({
    scope: [SheetsScopes.SPREADSHEETS].join(" "),
    onSuccess: (response: any) => {
      exportGoogleSheets(response);
    },
  });
  useEffect(() => {
    return () => {
      googleLogout();
    };
  }, []);
  return (
    <>
      {contextHolder}
      <Button
        className="btn-export-sheet"
        icon={<GoogleSheetIcon />}
        onClick={() => login()}
        loading={loading}
      >
        &nbsp; {t("exportToGoogleSheet")}
      </Button>
    </>
  );
}

export default ExportSheetButton;
