import { Part } from "app/models/Part";
import CadFileItem from "./CadFileItem";
import { Button, Card, Modal, notification } from "antd";
import partServices from "app/services/part.service";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./assemblyPartGroup.module.scss";
import CadFileIcon from "../../SVGs/CadFileIcon";
import AssemblyGroupDeleteModal from "./AssemblyGroupDeleteModal";

interface DefaultProps {
  parts?: Part[];
  onRequestCompleteAssemblyPart?: any;
  onDeletedAssemblyPartCallBack?: any;
  onDeletedPart?: any;
}
function AssemblyPartGroup(props: DefaultProps) {
  const {
    parts,
    onRequestCompleteAssemblyPart,
    onDeletedPart,
    onDeletedAssemblyPartCallBack,
  } = props;
  const [mergingParentFile, setMergingParentFile] = useState(false);

  const handleOnDeletedPart = (part: Part) => {
    if (onDeletedPart) {
      onDeletedPart(part);
    }
  };

  const RenderCardFileItem = (part: Part) => {
    return (
      <CadFileItem
        key={`child-part-${part.id}-${part.parentFileStoreKey}`}
        type={part.cuttingType}
        part={part}
        onDeletedPart={handleOnDeletedPart}
      />
    );
  };

  const handleOnRequestingCompleteAssemblyPart = (merging: boolean) => {
    setMergingParentFile(merging);
  };

  if (!parts || parts.length == 0) return <></>;
  return (
    <Card
      title={
        <ParentTitle
          part={parts[0]}
          onRequestCompleteAssemblyPart={onRequestCompleteAssemblyPart}
          onRequestingCompleteAssemblyPart={
            handleOnRequestingCompleteAssemblyPart
          }
          onDeletedAssemblyPartCallBack={onDeletedAssemblyPartCallBack}
        />
      }
      className="card-part group-bg assembly-card"
      loading={mergingParentFile}
    >
      {!mergingParentFile &&
        parts.map((part: Part) => {
          return RenderCardFileItem(part);
        })}
    </Card>
  );
}

interface ParentTitleDefaultProps {
  part?: Part;
  onRequestCompleteAssemblyPart?: any;
  onRequestingCompleteAssemblyPart?: any;
  onDeletedAssemblyPartCallBack?: any;
}
function ParentTitle(props: ParentTitleDefaultProps) {
  const {
    part,
    onRequestCompleteAssemblyPart,
    onRequestingCompleteAssemblyPart,
    onDeletedAssemblyPartCallBack,
  } = props;
  const [isOpenDxfPreview, setIsOpenDxfPreview] = useState(false);
  const { t } = useTranslation();
  const [downloadingParentFile, setDownloadingParentFile] = useState(false);
  const [mergingParentFile, setMergingParentFile] = useState(false);
  const [openConfirmCompleteAssembly, setOpenConfirmCompleteAssembly] =
    useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [openDelete, setOpenDelete] = useState(false);

  const downloadCadFile = async (part: Part) => {
    setDownloadingParentFile(true);
    if (part) {
      try {
        await partServices.downloadParentCadFile({
          id: part.id,
          name: part.parentFileName,
        });
      } catch (error) {}
    }
    setDownloadingParentFile(false);
  };

  const renderModelPreviewDxfFile = (part: Part | undefined) => {
    if (!part?.parentPreviewUrl) {
      return <></>;
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxHeight: "80vh",
        }}
      >
        <img
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          src={part?.parentPreviewUrl}
          alt={`${part?.id}-${part?.partFileName}`}
        />
      </div>
    );
  };

  const requestCompleteAssemblyPart = async (part: Part) => {
    setOpenConfirmCompleteAssembly(false);
    setMergingParentFile(true);
    onRequestingCompleteAssemblyPart(true);
    if (part && part.parentFileStoreKey) {
      try {
        const manualPart = await partServices.requestCompleteAssemblyPart({
          parentKey: part?.parentFileStoreKey,
        });
        if (onRequestCompleteAssemblyPart) {
          setMergingParentFile(false);
          onRequestingCompleteAssemblyPart(false);
          onRequestCompleteAssemblyPart(part, manualPart);
        }
      } catch (error) {
        setMergingParentFile(false);
        onRequestingCompleteAssemblyPart(false);
        api.error({
          message: t("failed"),
          description: t("error.default"),
          placement: "topRight",
        });
      }
    }
  };

  if (!part) return <></>;

  return (
    <>
      {contextHolder}
      <div className="d-flex flex-column flex-md-row">
        <div className="col flex-col d-flex align-items-center">
          <h5 style={{ marginBottom: 0 }}>
            <b>{t("assembly")}</b>
          </h5>
          <Button
            className="btn-link"
            type="link"
            onClick={setOpenConfirmCompleteAssembly.bind(null, true)}
            disabled={mergingParentFile}
            loading={mergingParentFile}
          >
            {t("requestQuoteForCompleteAssembly")}
          </Button>
        </div>
        <div className="d-flex" style={{ gap: 10 }}>
          <Button
            className="btn-link"
            type="link"
            icon={<EyeOutlined />}
            onClick={setIsOpenDxfPreview.bind(null, true)}
            disabled={mergingParentFile}
          >
            {t("preview")}
          </Button>
          <Button
            className="btn-link"
            type="link"
            onClick={downloadCadFile.bind(null, part)}
            disabled={downloadingParentFile || mergingParentFile}
            loading={downloadingParentFile}
          >
            <CadFileIcon />
            <span style={{ paddingLeft: 10 }}>{part?.parentFileName}</span>
          </Button>
          <Button
            danger
            className="btn-link"
            onClick={setOpenDelete.bind(null, true)}
          >
            <DeleteOutlined />
            <span>{t("delete")}</span>
          </Button>
        </div>
      </div>
      <Modal
        open={isOpenDxfPreview}
        width={"calc(60vw - 46px)"}
        onCancel={setIsOpenDxfPreview.bind(null, false)}
        footer={null}
        centered
        className="v3dmodel"
        maskClosable={false}
      >
        {renderModelPreviewDxfFile(part)}
      </Modal>
      <Modal
        title={t("confirm")}
        open={openConfirmCompleteAssembly}
        onOk={requestCompleteAssemblyPart.bind(null, part)}
        onCancel={setOpenConfirmCompleteAssembly.bind(null, false)}
        cancelButtonProps={{
          disabled: mergingParentFile,
        }}
        okButtonProps={{
          loading: mergingParentFile,
        }}
        closable={!mergingParentFile}
        maskClosable={!mergingParentFile}
      >
        <p>{t("requestQuoteForCompleteAssembly.confirm")}</p>
      </Modal>
      <AssemblyGroupDeleteModal
        part={part}
        onDeletedCallBack={onDeletedAssemblyPartCallBack}
        onCanceledCallBack={setOpenDelete.bind(null, false)}
        showDeleteModalProp={openDelete}
      />
    </>
  );
}

export default AssemblyPartGroup;
