import { createSlice } from "@reduxjs/toolkit";
import { Price } from "../../models/Price";
import { Project } from "../../models/Project";

// const setPart = (
//   state: State,
//   action: {
//     payload: any;
//     type: string;
//   }
// ) => {
//
// };
type StateType = {
  data?: Project;
  deliveryCost?: Price;
  deletedParts: any[];
  uploadFiles: any;
  isCalcAll: boolean;
  partsStatus: any;
};
const initialState: StateType = {
  deletedParts: [],
  uploadFiles: {},
  isCalcAll: false,
  partsStatus: {},
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (state, action) => {
      state.data = action.payload;
      state.deletedParts = [];
      state.uploadFiles = {};
      state.partsStatus = {};
    },
    clearProject: (state) => {
      state.data = undefined;
      state.deliveryCost = undefined;
      state.uploadFiles = {};
      state.partsStatus = {};
    },
    setPart: (state, action) => {
      if (state.data) {
        const parts = [...(state.data.parts || [])];
        const i = parts.findIndex((p) => p.id === action.payload.id);
        if (i > -1) {
          parts[i] = action.payload;
          if(!(action.payload.adaptedUnitPrice < 0)) {
            state.data.adapted = true;
          }
        } else {
          parts.push(action.payload);
        }
        state.data.parts = [...parts];
      }
    },
    setUploadPart: (state, action) => {
      state.uploadFiles[action.payload.key] = action.payload.data;
    },
    setDeliveryCost: (state, action) => {
      state.deliveryCost = action.payload;
    },
    setDeliveryOption: (state, action) => {
      if (state.data) {
        state.data.deliveryOption = action.payload;
      }
    },
    setStatus: (state, action) => {
      if (state.data) {
        state.data.status = action.payload;
      }
    },
    setPartStatus: (state, action) => {
      if (action.payload.id) {
        state.partsStatus[`${action.payload.id}`] = action.payload.data;
      }
    },
    callCalculateAll: (state, action) => {
      state.isCalcAll = true;
    },
    resetCalculateAll: (state, action) => {
      state.isCalcAll = false;
    },
    deletePart: (state, action) => {
      if (state.data && state.data.parts) {
        state.data.parts = state.data.parts.filter(
          (p) => p.id !== action.payload
        );
      }
    },
    deleteUploadPart: (state, action) => {
      delete state.uploadFiles[action.payload];
    },
    setProjectParts: (state, action) => {
      if (state.data && state.data.parts) {
        state.data.parts = action.payload;
      }
    },
    // loop to the part and delete the part that have that parentFileStoreKey, in case item in array get first index check if the same parentFileStoreKey delete it
    deletePartByParentFile: (state, action) => {
      if (state.data && state.data.parts) {
        state.data.parts = state.data.parts.filter(
          (p) => p.parentFileStoreKey !== action.payload
        );
      }
    },
  },
});

export const projectActions = projectSlice.actions;
export const projectSelector = (state: any) => state.project;
export const projectSateSelector = (state: any) => state;
export default projectSlice.reducer;
