/* eslint-disable jsx-a11y/iframe-has-title */
import { CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Modal, Space, Typography } from "antd";
import Tree, { DataNode } from "antd/es/tree";
import { LayerType } from "app/models/LayerType";
import partServices from "app/services/part.service";
import { DxfParser, ILayer } from "dxf-parser";
import { DxfViewer } from "dxf-viewer";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import montserrat from "../../../assets/fonts/Montserrat-Regular.ttf";
import { Part } from "../../models/Part";
import ButtonSelect from "../Button/ButtonSelect";
import "./CadFileItem/cadFileItem.module.scss";
import threeInstance from "app/utils/threeInstance";
import { langSelector } from "app/redux/slides/locale.slide";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";
import { ShopMaterial } from "app/models/ShopMaterial";

interface DefaultProps extends HTMLAttributes<any> {
  part: Part;
  selectedLayers?: any;
  onLayerLoaded?: any;
  onSelectedLayers?: any;
  onDxfLayerLoad?: any;
}

function DxfSelectLayer(props: DefaultProps) {
  const { part, onLayerLoaded, onSelectedLayers, onDxfLayerLoad } = props;
  const lang = useSelector(langSelector);
  const { t } = useTranslation();
  const [isOpenDxfPreview, setIsOpenDxfPreview] = useState(false);
  const [key] = useState(Date.now());
  const [loading, setLoading] = useState(true);
  const [layers, setLayers] = useState<ILayer[]>();
  const [dataLayers, setDataLayers] = useState<DataNode[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [selectedLayers, setSelectedLayers] = useState<any[]>(
    Object.keys(props.selectedLayers || {}) || []
  );
  const [dxf, setDxf] = useState<any>();
  const [dxfViewer, setDxfViewer] = useState<DxfViewer>();
  const [dxfBlobUrl, setDxfBlobUrl] = useState<string | null>(null);
  const [layerTypeSelected, setLayerTypeSelected] = useState<any>({
    ...props.selectedLayers,
  });
  const config = useSelector(configSelector);

  const canvasRef = useRef<any>(null);

  const getDxfContent = async () => {
    setLoading(true);
    if (part && part.partFileName) {
      try {
        const rs = await partServices.getDxfContent(part?.id);
        const reader = new FileReader();
        reader.onload = () => {
          const text: any = reader.result;
          const parser = new DxfParser();
          const dxf = parser.parseSync(text);
          setDxf(dxf);

          const blob = new Blob([text], { type: "application/dxf" });
          const blobUrl = URL.createObjectURL(blob);
          setDxfBlobUrl(blobUrl);

          const layers = dxf?.tables.layer.layers;
          let checkedKeys: any[] = [];
          if (layers) {
            const l = Object.keys(layers).map((l) => {
              layers[l].visible =
                selectedLayers && selectedLayers.length > 0
                  ? selectedLayers.findIndex((s) => s == layers[l].name) > -1
                    ? true
                    : false
                  : true;
              if (layers[l].visible) {
                checkedKeys.push(layers[l].name);
              }
              if (!layerTypeSelected[layers[l].name]) {
                layerTypeSelected[layers[l].name] = LayerType.CUT;
              }
              return layers[l];
            });
            setLayerTypeSelected(layerTypeSelected);
            setCheckedKeys(checkedKeys);
            setLayers(l);
            if (onLayerLoaded)
              onLayerLoaded(["all-layers", ...l.map((l) => l.name)]);
            if (onDxfLayerLoad) {
              onDxfLayerLoad(layerTypeSelected);
            }
          }
        };

        reader.readAsText(rs, "iso-8859-1");
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  const renderToCanvas = async (dxf: any) => {
    const canvas = canvasRef.current;
    const option: any = {
      clearColor: threeInstance.color,
      autoResize: true,
      colorCorrection: true,
      sceneOptions: {
        wireframeMesh: true,
      },
    };
    if (canvas) {
      const viewer = new DxfViewer(canvas, option);
      const loadData: any = {
        url: dxfBlobUrl,
        fonts: [montserrat],
        worerFactory: DxfViewer.SetupWorker(),
      };
      await viewer.Load(loadData);
      if (layers) {
        layers.map((l: any) => {
          viewer.ShowLayer(
            l.name,
            selectedLayers && selectedLayers.length > 0
              ? selectedLayers.findIndex((s) => s == l.name) > -1
                ? true
                : false
              : true
          );
        });
      }
      setDxfViewer(viewer);
    }
  };

  let items: MenuProps["items"] = [];

  if (
    config.shopMaterial == ShopMaterial.PLASTIC ||
    config.shopMaterial == ShopMaterial.RUBBER
  ) {
    items = [
      {
        key: LayerType.CUT,
        label: t(`layer.type.${LayerType.CUT}`),
      },
    ];
  } else {
    items = [
      {
        key: LayerType.CUT,
        label: t(`layer.type.${LayerType.CUT}`),
      },
      {
        key: LayerType.FOLD,
        label: t(`layer.type.${LayerType.FOLD}`),
      },
      {
        key: LayerType.MARK,
        label: t(`layer.type.${LayerType.MARK}`),
      },
    ];
  }

  useEffect(() => {
    if (layers) {
      const l = layers.map((l) => {
        return {
          title: <div className="d-flex">{l.name}</div>,
          key: l.name,
        };
      });
      const treeData: DataNode[] = [
        {
          title: t("part.allLayers"),
          key: "all-layers",
          children: l,
        },
      ];
      setDataLayers(treeData);
    }
  }, [layers, lang]);
  useEffect(() => {
    if (part && part.partFileName) {
      getDxfContent();
    }
  }, [part]);

  useEffect(() => {
    if (isOpenDxfPreview) {
      if (selectedLayers && selectedLayers.length > 0)
        setCheckedKeys(selectedLayers);
      renderToCanvas(dxf);
    } else {
    }
  }, [isOpenDxfPreview]);

  const onCheck = (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue);
    if (dxfViewer && layers) {
      layers.map((l) => {
        dxfViewer.ShowLayer(l.name, checkedKeysValue.indexOf(l.name) > -1);
      });
    }
  };

  const onSelect = (selectedKeysValue: any[], info: any) => {
    const value: string = info.node.key;
    const i = checkedKeys.findIndex((l) => l == value);
    let values: string[] = [...checkedKeys];
    if (i > -1) {
      values.splice(i, 1);
      dxfViewer?.ShowLayer(value, false);
    } else {
      values.push(`${value}`);
      dxfViewer?.ShowLayer(value, true);
    }
    setCheckedKeys(values);
  };

  const onOk = () => {
    if (checkedKeys.length > 0) {
      const ls = checkedKeys;
      const data: any = {};
      checkedKeys.map((k) => {
        data[k] = layerTypeSelected[k];
      });
      if (onSelectedLayers) onSelectedLayers(data);
      setSelectedLayers(ls);
    } else {
      const ls: any = layers?.map((l) => l.name);
      const data: any = {};
      checkedKeys.map((k) => {
        data[k] = layerTypeSelected[k];
      });
      ls.push("all-layers");
      if (onSelectedLayers) onSelectedLayers(data);
      setSelectedLayers(ls);
      setCheckedKeys(checkedKeys);
    }

    setIsOpenDxfPreview(false);
  };

  const onCancel = () => {
    setSelectedLayers(Object.keys(props.selectedLayers));
    setIsOpenDxfPreview(false);
  };

  const onLayerTypeChanged = (name: string) => (e: any) => {
    setLayerTypeSelected({
      ...layerTypeSelected,
      [name]: e.key,
    });
  };

  if (!part || loading)
    return (
      <>
        <LoadingOutlined />
      </>
    );

  return (
    <>
      <div className="pointer" onClick={setIsOpenDxfPreview.bind(null, true)}>
        {(selectedLayers?.length === 0 ||
          selectedLayers?.findIndex((l) => l === "all-layers") > -1) && (
          // <a className="text-primary">All layers</a>
          <ButtonSelect>{t("part.allLayers")}</ButtonSelect>
        )}
        {selectedLayers?.length > 0 &&
          selectedLayers.findIndex((l) => l === "all-layers") === -1 && (
            <ButtonSelect tooltip={selectedLayers.join(", ")}>
              {selectedLayers.join(", ")}
            </ButtonSelect>
          )}
      </div>
      <Modal
        open={isOpenDxfPreview}
        width={"calc(80vw - 46px)"}
        centered
        className="v3dmodel"
        maskClosable={false}
        okButtonProps={{
          className: "me-3 mb-3",
        }}
        onOk={onOk}
        onCancel={onCancel}
      >
        <div className="row w-100">
          <div className="col col-4 py-4 layers">
            <h6 className="mb-3">{t("part.cuttingLayers")}</h6>
            <div className="row">
              <div className="col">
                <Tree
                  key={`layers-${lang}`}
                  style={{
                    background: "transparent",
                  }}
                  checkable
                  expandedKeys={["all-layers"]}
                  autoExpandParent={true}
                  onCheck={onCheck}
                  checkedKeys={checkedKeys}
                  treeData={dataLayers}
                  onSelect={onSelect}
                  blockNode
                />
              </div>
              <div className="col-4 d-flex flex-column align-items-end">
                <div className="layer-type"></div>
                {layers?.map((l, i) => (
                  <div className="layer-type" key={`layer-type-${i}`}>
                    <Dropdown
                      // className="ms-auto text-primary"
                      trigger={["click"]}
                      menu={{
                        items,
                        selectable: true,
                        defaultSelectedKeys: [LayerType.CUT],
                        onSelect: onLayerTypeChanged(l.name),
                      }}
                      disabled={checkedKeys.indexOf(l.name) === -1}
                    >
                      <Typography.Link>
                        <Space>
                          <span>
                            {t(`layer.type.${layerTypeSelected[l.name]}`)}
                          </span>
                          <CaretDownOutlined />
                        </Space>
                      </Typography.Link>
                    </Dropdown>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col col-8">
            <div ref={canvasRef} className="canvasContainer"></div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DxfSelectLayer;
