const HUIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" {...props}>
    <g fillRule="evenodd" strokeWidth="1pt">
    <rect width="640" height="480" fill="#cf2436"/>
        <rect y="160" width="640" height="160" fill="#fff"/>
        <rect y="320" width="640" height="160" fill="#45714f"/>
    </g>
  </svg>
);
export default HUIcon;
