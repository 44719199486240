/* eslint-disable no-new-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  SettingOutlined,
  UploadOutlined,
  WarningOutlined
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Select,
  SelectProps,
  Space,
  Tag,
  Upload,
  UploadFile,
  UploadProps,
  notification
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { RcFile } from "antd/lib/upload/interface";
import { DeburringType } from "app/models/DeburringType";
import { LaserGas } from "app/models/LaserGas";
import { CurrencyExchange } from "app/models/User";
import { configSelector } from "app/redux/slides/config.slide";
import Utils from "app/utils";
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CuttingType } from "../../../models/CuttingType";
import { Material } from "../../../models/Material";
import { MaterialGroup } from "../../../models/MaterialGroup";
import { Part, PartPreview } from "../../../models/Part";
import { ActionMode } from "../../../models/PartActionMode";
import { PartViewMode } from "../../../models/PartViewMode";
import { Project, ProjectStatus } from "../../../models/Project";
import { SurfaceQuality } from "../../../models/SurfaceQuality";
import { SurfaceTreatment } from "../../../models/SurfaceTreatment";
import { TechDrawingData } from "../../../models/TechDrawingData";
import { UserRole } from "../../../models/UserRole";
import { authSelector } from "../../../redux/slides/auth.slide";
import { materialSelector } from "../../../redux/slides/material.slide";
import {
  projectActions,
  projectSelector,
} from "../../../redux/slides/project.slide";
import partServices from "../../../services/part.service";
import AlertManualReason from "../../Alert/AlertManualReason";
import AlertPartWarning from "../../Alert/AlertPartWarning";
import InputNumberInline from "../../Form/InputNumberInline";
import CurrencyFormat from "../../Format/CurrencyFormat";
import MaterialNumber from "../../Material/MaterialNumber";
import PdfFileIcon from "../../SVGs/PdfFileIcon";
import CadFileItemDeleteModal from "./CadFileItemDeleteModal";
import CadFileItemInfo from "./CadFileItemInfo";
import ComplianceSelector from "../ComplianceSelector";
import DropdownActions from "../DropdownActions";
import EmissionReportView from "../EmissionReportView";
import MaterialSelectModal from "../MaterialSelectModal";
import MiniReport from "../MiniReport";
import PriceAdaptForm from "../PriceAdaptForm";
import PriceReference from "../PriceReference";
import ProcessingReport from "../ProcessingReport";
import RobotCalc from "../RobotCalc";
import "./cadFileItem.module.scss";

const { Option } = Select;

const isStp = (fileName?: string) => {
  return (
    !!fileName &&
    (fileName.toLowerCase().indexOf(".step") > -1 ||
      fileName.toLowerCase().indexOf(".stp") > -1)
  );
};

interface DefaultProps extends HTMLAttributes<any> {
  file?: UploadFile<any>;
  pdfFile?: RcFile;
  type?: CuttingType;
  part?: Part;
  mode?: PartViewMode;
  pricePreview?: number;
  pricePreviewLoading?: boolean;
  onChange?: any;
  onQuantityChange?: any;
  onPartUploadedChange?: any;
  onPartRender?: any;
  materialGroups?: MaterialGroup[];
  groups?: any;
  dataCuttingType?: any;
  onDeletedPart?: any;
  cadFileCommonError?: any;
}

const isShowCompliance = process.env.REACT_APP_SHOW_COMPLIANCE && process.env.REACT_APP_SHOW_COMPLIANCE == 'true';

function CadFileItemTechnicalPlastics(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const { data, isCalcAll } = useSelector(projectSelector);
  const { projectId, accessCode } = useParams();
  const {
    type,
    file,
    pdfFile,
    mode = PartViewMode.EDIT,
    pricePreview,
    pricePreviewLoading,
    onChange,
    onQuantityChange,
    onPartUploadedChange,
    onPartRender,
    materialGroups,
    dataCuttingType,
    onDeletedPart,
    cadFileCommonError,
    ...viewProps
  } = props;
  const dataPart: any = props.part;
  const [cuttingType] = useState(dataCuttingType);
  const { groups } = useSelector(materialSelector);
  const [part, setPart] = useState<Part>(dataPart);
  const uploading = useRef(false);
  const adaptForm: any = useRef();
  const [project] = useState<Project>(data);
  const [uploaded, setUploaded] = useState(!!dataPart);
  const [parameterUploading, setParameterUploading] = useState(false);
  const [partForm] = Form.useForm();
  const [quantityForm] = Form.useForm();
  const [disableHasTolerences, setDisableHasTolerences] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [file2D, setFile2D] = useState<RcFile | undefined>(pdfFile);
  const [technicalDrawingError, setTechnicalDrawingError] = useState<
    string | null
  >(null);
  const [materialGroup, setMaterialGroup] = useState(
    !!part
      ? groups.find((x: MaterialGroup) => x.id === part?.materialGroupId) ||
          undefined
      : undefined
  );
  const [material, setMaterial] = useState<Material | undefined>(
    dataPart?.material
  );
  useState<MaterialGroup[]>();
  const [viewMode, setViewMode] = useState<PartViewMode>(
    mode ||
      ((!!file || (part && part.totalPrice === null && part.auto)) &&
        PartViewMode.EDIT) ||
      (part &&
        (part.totalPrice !== null || !part?.auto) &&
        (project?.selfCalculation
          ? PartViewMode.ADAPT
          : PartViewMode.UPDATED)) ||
      PartViewMode.EDIT
  );
  const [referenceCosts, setReferenceCosts] = useState<any>();
  const [error, setError] = useState<any>();
  const [deleted, setDeleted] = useState(false);
  const [isProcessingReport, setIsProcessingReport] = useState(false);
  const [uploadingTechDrawingFile, setUploadingTechDrawingFile] =
    useState(false);
  const [requestingTechDrawingData, setRequestingTechDrawingData] =
    useState(false);
  const [errorTechDrawingData, setErrorTechDrawingData] = useState<any>();
  const [techDrawingData, setTechDrawingData] = useState<TechDrawingData>();
  const [dataPartForm, setDataPartForm] = useState<any>(part || {});
  const [checkDesignPrinciple, setcheckDesignPrinciple] = useState(true);
  const [partPreview, setPartPreview] = useState<PartPreview>();
  const [isDirectSubmit, setIsDirectSubmit] = useState(false);
  const currencyInfo: CurrencyExchange = {
    exchangeRate: project?.order?.price?.currencyExchangeRate,
    symbol: project?.order?.price?.currencySymbol,
    isoCode: project?.order?.price?.currencyIsoCode,
  };

  const surfaceFinishs: SelectProps["options"] = [
    {
      label: t("surfaceQuality.FINELY_FINISHED"),
      value: SurfaceQuality.FINELY_FINISHED,
    },
    {
      label: t("surfaceQuality.SETTLED"),
      value: SurfaceQuality.SETTLED,
    },
    {
      label: t("surfaceQuality.ROUGHED"),
      value: SurfaceQuality.ROUGHED,
    },
  ];

  const renderToleranzen = (
    <div style={{ maxWidth: "600px" }}>
      <div>{t("part.tooltip.tolerances.message1")}</div>
      <img
        width={"100%"}
        src={process.env.PUBLIC_URL+"/images/ToleranzmaBe_Information_Bild.jpg"}
        alt="ToleranzmaBe_Information_Bild"
      ></img>
      <p>
        {t("part.numberOfThreads")} <br />1 ({t("see")} 1)
        <br />
        <br />
        {t("part.numberOfFits")}
        <br />4 ({t("see")} 2)
        <br />
        <br />
        {t("part.form.hasTolerances")}
        <br />
        {t("yes")} ({t("part.tooltip.tolerances.label")}: h5 = IT5)
      </p>
    </div>
  );

  const disableClickEvent = (event: any) => {
    event.stopPropagation();
  };

  const onRequestTechDrawingData = async (file: any) => {
    setRequestingTechDrawingData(true);
    try {
      const rs: TechDrawingData = await partServices.requestTechDrawingData(
        file
      );
      setTechDrawingData({
        ...rs,
        numOfThreads: rs.numOfThreads || 0,
        numOfTolerances: rs.numOfTolerances || 0,
        hasSmallTolerance: !!rs.hasSmallTolerance,
      });
    } catch (error: any) {
      // setErrorTechDrawingData(error?.message);
    }
    setRequestingTechDrawingData(false);
  };

  const onUploadTechDrawingFile = async (file: any) => {
    setUploadingTechDrawingFile(true);
    try {
      await partServices.uploadTechDrawingFile({
        id: part.id,
        file,
      });
      setFile2D(undefined);
    } catch (error) {}
    setUploadingTechDrawingFile(false);
  };

  const upload2DProps: UploadProps = {
    beforeUpload: async (file: any, fileList) => {
      setFile2D(file);
      onRequestTechDrawingData(file);
      return false;
    },
    multiple: false,
    fileList: [],
    accept: `.pdf`,
  };

  const uploadFile = async () => {
    setError(undefined);
    if (!file) return;
    if (part || uploading.current || uploaded) return;
    uploading.current = true;
    dispatch(
      projectActions.setUploadPart({
        key: file.uid,
        data: null,
      })
    );
    if (pdfFile) onRequestTechDrawingData(pdfFile);
    try {
      if (file && (file.size || 0) > 1024 * 1000 * 20) {
        throw new Object({
          message: t("error.part.maxSize"),
          error: "MAX_SIZE",
        });
      }
      let ct: CuttingType = cuttingType;
      // if (
      //   part == undefined &&
      //   (cuttingType == CuttingType.MILLING_AND_TURNING || isStp(file.name))
      // ) {
      //   partServices
      //     .uploadFileToPreview({
      //       cuttingType: ct,
      //       cadFile: file,
      //     })
      //     .then((rs: PartPreview) => setPartPreview(rs));
      // }

      const rs: Part = await partServices.upload({
        projectId,
        cuttingType: ct,
        cadFile: file,
      });

      rs.surfaceTreatmentIds = [];
      setPart(rs);
      partForm.setFieldValue("partId", rs.id);
      setUploaded(true);
      setViewMode(PartViewMode.EDIT);
      dispatch(projectActions.setPart(rs));
      if (onPartUploadedChange) {
        onPartUploadedChange(rs);
      }
      dispatch(
        projectActions.setUploadPart({
          key: file.uid,
          data: rs,
        })
      );
      dispatch(projectActions.setPartStatus({ id: rs.id, data: viewMode }));
    } catch (error: any) {
      if (error && error.error !== "MAX_SIZE")
        error.message = t("project.error.calculation");
      setError(error);
      dispatch(
        projectActions.setUploadPart({
          key: file.uid,
          data: false,
        })
      );
    }
  };

  const updateParameters = async (values: any) => {
    setError(undefined);
    setParameterUploading(true);
    try {
      if (file2D) {
        await onUploadTechDrawingFile(file2D);
      }
      let rs: any = undefined;

      switch (part.cuttingType) {
        case CuttingType.MILLING_AND_TURNING:
          rs = await partServices.updateParameters({
            ...values,
            techDrawingData: JSON.stringify(techDrawingData),
          });
          break;
        default:
          break;
      }
      if (rs) {
        dispatch(projectActions.setPart(rs));
        if (rs.auto && part?.materialId != values.materialId) {
          getReferenceCosts();
        }
        setViewMode(
          project?.selfCalculation ? PartViewMode.ADAPT : PartViewMode.UPDATED
        );
        setPart(rs);
        try {
          if (rs.techDrawingData) {
            const check = await partServices.checkDesignPrinciple(rs.id);
            setcheckDesignPrinciple(check);
          }
        } catch (error) {}
      }
    } catch (error: any) {
      if (error) {
        error.message = t("project.error.calculation");
        if (cadFileCommonError && cadFileCommonError[error.exception]) {
          error.message = t(cadFileCommonError[error.exception]);
        }
      }
      setError(error);
    }
    setParameterUploading(false);
  };

  const getReferenceCosts = async () => {
    if (viewMode === PartViewMode.READ_ONLY) return;
    try {
      const rs = await partServices.getReferenceCosts(part?.id);
      setReferenceCosts(rs);
    } catch (error) {}
  };

  useEffect(() => {
    if (part) {
      partForm.setFieldValue("partId", part?.id);
      partForm.setFieldValue("materialGroupId", materialGroup?.id);
    }
    uploadFile();
    if (onPartRender) {
      onPartRender(cuttingType);
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(part);
    }
    if (onPartUploadedChange) {
      onPartUploadedChange(part);
    }
    if (part) {
      quantityForm.setFieldValue("quantity", part.quantity);
      if (part?.auto && part?.totalPrice > 0 && !accessCode) {
        getReferenceCosts();
      }
      if (part.techDrawingData) {
        setTechDrawingData({
          ...part.techDrawingData,
          numOfThreads: part.techDrawingData.numOfThreads || 0,
          numOfTolerances: part.techDrawingData.numOfTolerances || 0,
          hasSmallTolerance: !!part.techDrawingData.hasSmallTolerance,
        });
      } else {
        setTechDrawingData(undefined);
      }
      // override init value
      if (part?.surfaceQuality) {
        partForm.setFieldValue('surfaceQuality', part?.surfaceQuality);
      }
    }
  }, [part]);

  useEffect(() => {
    if (techDrawingData) {
      partForm.setFieldValue(
        "numberOfThreads",
        techDrawingData.numOfThreads || 0
      );
      partForm.setFieldValue(
        "numberOfFits",
        techDrawingData.numOfTolerances || 0
      );
      partForm.setFieldValue(
        "hasTolerances",
        !!techDrawingData.hasSmallTolerance
      );

      setDataPartForm({
        ...dataPartForm,
        numberOfThreads: techDrawingData.numOfThreads || 0,
        numberOfFits: techDrawingData.numOfTolerances || 0,
        hasTolerances: techDrawingData.hasSmallTolerance,
      });

      const isDisabled =
        techDrawingData?.hasSmallTolerance != null ||
        (techDrawingData?.numOfTolerances || 0) === 0;
      setDisableHasTolerences(isDisabled);
    }
  }, [techDrawingData]);

  useEffect(() => {
    if (materialGroup) {
      partForm.setFieldValue(
        "surfaceTreatmentIds",
        materialGroup.id === part?.materialGroupId
          ? part?.surfaceTreatmentIds
          : []
      );

      let surfaceTreatments: SurfaceTreatment[] = [];
      materialGroup.surfaceTreatments?.map((s: any) => {
        if (s.active) {
          surfaceTreatments.push(s);
        }
      });
      partForm.setFieldValue("materialGroupId", materialGroup.id);
    }
  }, [materialGroup]);

  useEffect(() => {
    if (material) {
      partForm.setFieldValue("materialId", material?.id);
      const g = materialGroups?.find((g) =>
        g.id === material.group?.id
      );
      setMaterialGroup(g);
    }
  }, [material]);

  useEffect(() => {
    if (isCalcAll && viewMode === PartViewMode.EDIT) {
      partForm.submit();
    }
  }, [isCalcAll]);

  useEffect(() => {
    if (part) {
      dispatch(projectActions.setPartStatus({ id: part.id, data: viewMode }));
    }
  }, [viewMode]);

  const onEdit = (event: any) => {
    event.stopPropagation();
    setViewMode(PartViewMode.EDIT);
    setMaterial(part?.material);
    if (part) {
      setDataPartForm(part);
      partForm.setFieldsValue(part);
      partForm.setFieldValue("numberOfThreads", part.numberOfThreads || 0);
      partForm.setFieldValue("numberOfFits", part.numberOfFits || 0);
      partForm.setFieldValue("hasTolerances", !!part.hasTolerances);
      setTimeout(() => {
        partForm.setFieldValue("requestedComplianceIds", part.requestedCompliances?.map(c => c.id));
      }, 300);
      const isDisabled =
        part.techDrawingData?.hasSmallTolerance != null ||
        (part.techDrawingData?.numOfTolerances || part.numberOfFits || 0) === 0;
      setDisableHasTolerences(isDisabled);
    }
  };

  const onEditCancel = () => {
    setViewMode(
      project?.selfCalculation ? PartViewMode.ADAPT : PartViewMode.UPDATED
    );
    if (part && part.techDrawingData) {
      setTechDrawingData({
        ...part.techDrawingData,
        numOfThreads: part.techDrawingData.numOfThreads || 0,
        numOfTolerances: part.techDrawingData.numOfTolerances || 0,
        hasSmallTolerance: !!part.techDrawingData.hasSmallTolerance,
      });
    } else {
      setTechDrawingData(undefined);
    }
    setFile2D(undefined);
  };

  const showDeleteModal = () => {
    setOpenDelete(true);
  };

  const cancelDeleteModal = () => {
    setOpenDelete(false);
  };

  const onActionsSubmmitted = (mode: any) => {
    try {
      switch (mode) {
        case ActionMode.MOVE:
          setDeleted(true);
          api.info({
            message: `Part Move`,
            description: <>Part "{part.partFileName} moved!"</>,
            placement: "topRight",
          });
          break;
        case ActionMode.VIEW:
          break;
        default:
          break;
      }
    } catch (error) {}
  };

  const onMaterialSelected = (data: Material) => {
    setMaterial(data);
    setMaterialGroup(data.group);
    partForm.setFieldValue("materialId", data.id);
  };

  const onPartChange = (values: any) => {
    if (
      partForm.getFieldValue("hasTolerances") &&
      (partForm.getFieldValue("numberOfFits") || 0) === 0
    ) {
      partForm.setFieldValue("hasTolerances", false);
      values.hasTolerances = false;
    }
    const isDisabled =
      techDrawingData?.hasSmallTolerance != null ||
      (part?.techDrawingData?.numOfTolerances ||
        partForm.getFieldValue("numberOfFits") ||
        0) == 0;
    setDisableHasTolerences(isDisabled);
    setDataPartForm({
      ...dataPartForm,
      ...values,
    });
  };

  const onFormQuantitySubmit = async (quantity: any) => {
    try {
      const rs = await partServices.updateQuantity({
        id: part?.id,
        quantity,
      });
      setPart(rs);
      dispatch(projectActions.setPart(rs));
      if (adaptForm) {
        adaptForm.current?.updateAdapt(rs.unitPrice);
      }
      if (onQuantityChange) onQuantityChange(quantity);
    } catch (error) {}
  };

  const onPriceAdapted = (part: any) => {
    setPart({ ...part });
    if (adaptForm) {
      adaptForm.current?.updateAdapt(part.adaptedUnitPrice);
    }
  };

  const onQuantityChanged = async (value: number, quanlities: any[]) => {
    try {
      await getReferenceCosts();
    } catch (error) {}
  };

  useEffect(() => {
    if (project?.archived == true) {
      setViewMode(PartViewMode.READ_ONLY);
    }
  }, [project?.archived]);

  const showDeleteButton =
    error ||
    (uploaded &&
      ((viewMode === PartViewMode.ADAPT && project?.selfCalculation) ||
        (viewMode !== PartViewMode.CHECKOUT &&
          auth.user?.sub === project?.createdBy?.email)));

  const showCalculationButton = uploaded && viewMode === PartViewMode.EDIT;
  const showActionButton =
    viewMode !== PartViewMode.READ_ONLY &&
    part &&
    ((showDeleteButton && !showCalculationButton) || !(part?.totalPrice > 0));

  const validatePartBeforeSubmit = async () => {
    let valid = true;

    setTechnicalDrawingError(null);
    const is2DFileExsit =
    part && part?.pdfFileName ? true : file2D ? true : false;

    const isTechnicalDrawingRequired =
    config.technicalDrawingRequired && !is2DFileExsit;

    if (isTechnicalDrawingRequired) {
      setTechnicalDrawingError(t("required"));
      valid = false;
    }

    return partForm
      .validateFields()
      .then(() => {
        return true && valid;
      })
      .catch(() => {
        return false;
      });
  };

  const handlePartFormDirectSubmit = async () => {
    const valid = await validatePartBeforeSubmit();
    if (valid) {
      setError(undefined);
      setParameterUploading(true);
      setIsDirectSubmit(true);
    }
  };

  useEffect(() => {
    if (uploaded && isDirectSubmit) {
      partForm.submit();
      setIsDirectSubmit(false);
    }
  }, [uploaded, isDirectSubmit]);

  useEffect(() => {
    setTechnicalDrawingError(null);
  }, [viewMode]);

  const isEditMode = viewMode === PartViewMode.EDIT;

  const QuantityInput = () => {
    return (
      <Space direction="vertical">
        <Form.Item
          label={<>{t("quantity")}</>}
          name="quantity"
          messageVariables={{
            label: t("quantity"),
          }}
          rules={[{ required: true }]}
        >
          <InputNumber type="number" min={1} />
        </Form.Item>
      </Space>
    );
  };

  const onDeletedCallBack = () => {
    setDeleted(true);
    if (onDeletedPart) {
      onDeletedPart(part);
    }
  };

  if (deleted) return <></>;

  return (
    <>
      {contextHolder}
      <div {...viewProps} className={`card-part ${props.className}`}>
        <div className={`b-calculate ${parameterUploading && "active"}`}>
          <div className="text-center">
            <SettingOutlined style={{ fontSize: "4rem" }} spin />
            <p className="mt-2">{t("calculating")}...</p>
          </div>
        </div>

        <div className="row">
          {(uploading || part || error) && (
            <CadFileItemInfo
              partPreview={partPreview}
              part={part}
              uploading={uploading}
              cuttingType={cuttingType}
              file={file}
            />
          )}
          <div className="col part-info px-md-4">
            <>
              <div
                className={`d-flex flex-column flex-md-row ${
                  technicalDrawingError ? "mb-1" : "mb-3"
                }`}
              >
                {auth.user?.role === UserRole.SELLER && !isEditMode && (
                  <>
                    <MiniReport
                      partId={part?.id}
                      projectId={projectId}
                      onAdapted={onPriceAdapted}
                      readonly={viewMode === PartViewMode.READ_ONLY}
                    />
                    <Button
                      className="ms-md-3"
                      onClick={setIsProcessingReport.bind(null, true)}
                    >
                      <FileTextOutlined /> {t("part.proccessingReport")}
                    </Button>
                    <EmissionReportView part={part} />
                  </>
                )}
                {isEditMode && (
                  <div className="col flex-col">
                    <div className="row">
                      <div className="col col-12 col-md-6 col-lg-12">
                        <Upload {...upload2DProps} className="me-auto">
                          <div
                            className={
                              technicalDrawingError
                                ? "drawing-upload-error"
                                : ""
                            }
                          >
                            <Button
                              type="default"
                              icon={
                                file2D?.name || part?.pdfFileName ? (
                                  <PdfFileIcon width={16} height={16} />
                                ) : (
                                  <UploadOutlined />
                                )
                              }
                              loading={uploadingTechDrawingFile}
                              title={
                                file2D?.name ||
                                part?.pdfFileName ||
                                t("part.addDrawing") ||
                                ""
                              }
                              className="upload-button"
                            >
                              <label className="ms-2 text-ml-200px">
                                <span>
                                  {file2D?.name ||
                                    part?.pdfFileName ||
                                    t("part.addDrawing")}
                                </span>
                              </label>
                            </Button>
                            {technicalDrawingError && (
                              <div
                                className="pt-2"
                                role="alert"
                                style={{ maxWidth: 500 }}
                              >
                                <div className="helper-text">
                                  {technicalDrawingError}
                                </div>
                              </div>
                            )}
                          </div>
                        </Upload>
                        {errorTechDrawingData && (
                          <Alert
                            type="error"
                            message={errorTechDrawingData}
                            showIcon
                            className="ms-3"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* {showDeleteButton &&
                  isEditMode &&
                  (part?.unitPrice || !part?.auto) && (
                    <div className="d-flex">
                      <Form.Item className="mb-0 ms-3">
                        <Button
                          type="default"
                          icon={<DeleteOutlined />}
                          onClick={showDeleteModal}
                          danger
                        >
                          {t("delete")}
                        </Button>
                      </Form.Item>
                    </div>
                  )} */}

                <div className="d-flex">
                  {showCalculationButton &&
                    (part?.material) && (
                      <Form.Item className="mb-0 ms-3">
                        <Button
                          type="default"
                          onClick={onEditCancel}
                          disabled={requestingTechDrawingData}
                        >
                          {t("cancel")}
                        </Button>
                      </Form.Item>
                    )}
                  {viewMode == PartViewMode.EDIT && (
                    <Form.Item className="mb-0 ms-3">
                      <Button
                        type="primary"
                        htmlType="button"
                        icon={<SettingOutlined spin={parameterUploading} />}
                        disabled={requestingTechDrawingData}
                        onClick={handlePartFormDirectSubmit}
                      >
                        {t("calculate")}
                      </Button>
                    </Form.Item>
                  )}
                </div>

                {uploaded &&
                  viewMode !== PartViewMode.READ_ONLY &&
                  viewMode !== PartViewMode.EDIT &&
                  (viewMode !== PartViewMode.ADAPT ||
                    project?.selfCalculation) && (
                    <div className="ms-md-auto mb-3 mb-md-0">
                      <Button
                        className="ms-md-3"
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={onEdit}
                      >
                        {t("edit")}
                      </Button>
                    </div>
                  )}
                {showActionButton && (
                  <div className="ms-3" onClick={disableClickEvent}>
                    <DropdownActions
                      project={project}
                      part={part}
                      onSubmitted={onActionsSubmmitted}
                      hiddenViewBtn
                      hiddenDeleteBtn={false}
                      onDeleteClick={showDeleteModal}
                    />
                  </div>
                )}
              </div>
              {!(
                viewMode === PartViewMode.READ_ONLY &&
                auth.user?.role === UserRole.BUYER
              ) && <hr />}
            </>
            <AlertPartWarning part={part} />
            <AlertManualReason part={part} />
            {(!uploaded || viewMode === PartViewMode.EDIT) && (
              <Form
                form={partForm}
                layout="vertical"
                onFinish={updateParameters}
                onValuesChange={onPartChange}
                disabled={parameterUploading}
                initialValues={
                  part || {
                    materialProvided: false,
                    techDrawingFile: null,
                    numberOfFits: 0,
                    numberOfThreads: 0,
                    quantity: 1,
                    deburringType: DeburringType.NO,
                    surfaceQuality: SurfaceQuality.ROUGHED,
                    laserGas: LaserGas.N2,
                  }
                }
              >
                <Form.Item name="techDrawingFile" className="mb-0" hidden>
                  <Input />
                </Form.Item>
                <div>
                  {error && (
                    <Alert
                      className="mb-3"
                      message={error.message}
                      type="error"
                      icon={<MinusCircleOutlined />}
                      showIcon
                    />
                  )}

                  <Form.Item name="partId" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="materialGroupId" hidden>
                    <Input />
                  </Form.Item>
                  {(!error || uploaded) && (
                    <>
                      <div className="row">
                        <div className={`col col-12 col-md-3`}>
                          <div className="row">
                            <div className={`col col-md-12`}>
                              <Form.Item
                                className="mb-0"
                                label={t("material")}
                                name="materialId"
                                rules={[{ required: true }]}
                              >
                                <MaterialSelectModal
                                  materialProp={material}
                                  mode={mode}
                                  cuttingType={cuttingType || part.cuttingType}
                                  part={part}
                                  dataPart={dataPart}
                                  onMaterialSelectedCallBack={
                                    onMaterialSelected
                                  }
                                  requestingTechDrawingData={
                                    requestingTechDrawingData
                                  }
                                  techDrawingData={techDrawingData}
                                />
                              </Form.Item>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col col-12">
                              <Form.Item
                                className="mb-0"
                                name="materialProvided"
                                valuePropName="checked"
                              >
                                <Checkbox>
                                  {t("part.form.materialProvided")}
                                </Checkbox>
                              </Form.Item>
                            </div>
                          </div> */}
                        </div>
                        {isShowCompliance && <>
                          <div className="col col-6 col-lg-auto">
                            <Form.Item
                              label={t("part.confirmities")}
                            >
                              <label className="d-table">
                                {material?.compliances?.map(c => <Tag key={`compliances-${c.id}`} className="mb-2 app-tag">{c.name}</Tag>)}
                                {!material?.compliances?.length && "N/A"}
                              </label>
                            </Form.Item>
                          </div>
                          <div className="col col-6 col-lg-4">
                            <ComplianceSelector form={partForm} material={material} />
                          </div>
                        </>}
                        <div className={`col col-12 col-lg-2 d-flex gap-2`}>
                          {QuantityInput()}
                        </div>
                        {/* <div className="col col-6 col-md-6 col-lg">
                          <Form.Item
                            name="surfaceTreatmentIds"
                            label={t("part.surfaceTreatments")}
                          >
                            <SurfaceTreatmentSelector surfaceTreatments={surfaceTreatments} material={material} />
                          </Form.Item>
                        </div> */}
                      </div>
                      <hr />
                      <div>
                        <label className="fw-bold mb-2">
                          {t("part.tolerances")}{" "}
                          <Popover
                            placement="right"
                            title={`${t("example")}: ${t("part.tolerances")}`}
                            content={renderToleranzen}
                            trigger="hover"
                          >
                            <InfoCircleOutlined />
                          </Popover>
                        </label>
                        <div className="row">
                          <div className="col col-12 col-md-6 col-lg-2">
                            {!project?.selfCalculation &&
                              (requestingTechDrawingData ||
                                techDrawingData?.numOfThreads != null) && (
                                <Form.Item
                                  label={t("part.form.numberOfThreads")}
                                  name="numberOfThreads"
                                  rules={[{ required: true }]}
                                >
                                  <RobotCalc
                                    loading={requestingTechDrawingData}
                                    completed={
                                      !requestingTechDrawingData &&
                                      techDrawingData?.numOfThreads ==
                                        partForm.getFieldValue(
                                          "numberOfThreads"
                                        )
                                    }
                                    error={errorTechDrawingData}
                                  />
                                </Form.Item>
                              )}
                            {(project?.selfCalculation ||
                              !(
                                requestingTechDrawingData ||
                                techDrawingData?.numOfThreads != null
                              )) && (
                              <Form.Item
                                label={t("part.form.numberOfThreads")}
                                name="numberOfThreads"
                                rules={[{ required: true }]}
                              >
                                <InputNumber
                                  style={{ width: "7rem" }}
                                  type="number"
                                  min={0}
                                  addonAfter={
                                    requestingTechDrawingData ||
                                    techDrawingData?.numOfThreads != null ? (
                                      <RobotCalc
                                        loading={requestingTechDrawingData}
                                        completed={
                                          !requestingTechDrawingData &&
                                          techDrawingData?.numOfThreads ==
                                            partForm.getFieldValue(
                                              "numberOfThreads"
                                            )
                                        }
                                        error={errorTechDrawingData}
                                      />
                                    ) : null
                                  }
                                />
                              </Form.Item>
                            )}
                          </div>
                          <div className="col col-12 col-md-6 col-lg-3">
                            {!project?.selfCalculation &&
                              (requestingTechDrawingData ||
                                techDrawingData?.numOfTolerances != null) && (
                                <Form.Item
                                  label={t("part.form.numberOfFits")}
                                  name="numberOfFits"
                                  rules={[{ required: true }]}
                                >
                                  <RobotCalc
                                    loading={requestingTechDrawingData}
                                    completed={
                                      !requestingTechDrawingData &&
                                      techDrawingData?.numOfTolerances ==
                                        partForm.getFieldValue("numberOfFits")
                                    }
                                    error={errorTechDrawingData}
                                  />
                                </Form.Item>
                              )}
                            {(project?.selfCalculation ||
                              !(
                                requestingTechDrawingData ||
                                techDrawingData?.numOfTolerances != null
                              )) && (
                              <Form.Item
                                label={t("part.form.numberOfFits")}
                                name="numberOfFits"
                                rules={[{ required: true }]}
                              >
                                <InputNumber
                                  style={{ width: "7rem" }}
                                  type="number"
                                  min={0}
                                  // disabled={
                                  //   requestingTechDrawingData ||
                                  //   part?.techDrawingData?.numOfTolerances !=
                                  //     null
                                  // }
                                  addonAfter={
                                    requestingTechDrawingData ||
                                    techDrawingData?.numOfTolerances != null ? (
                                      <RobotCalc
                                        loading={requestingTechDrawingData}
                                        completed={
                                          !requestingTechDrawingData &&
                                          techDrawingData?.numOfTolerances ==
                                            partForm.getFieldValue(
                                              "numberOfFits"
                                            )
                                        }
                                        error={errorTechDrawingData}
                                      />
                                    ) : null
                                  }
                                />
                              </Form.Item>
                            )}
                          </div>
                          {/* <div className="col col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="mb-2 mb-lg-3 text-label">
                              {t("part.form.hasTolerances")}
                            </div>
                            <Space.Compact>
                              <Form.Item
                                name="hasTolerances"
                                label={t("part.form.hasTolerances")}
                                valuePropName="checked"
                                noStyle
                                hidden={
                                  requestingTechDrawingData ||
                                  techDrawingData?.hasSmallTolerance != null
                                }
                              >
                                <Switch
                                  checkedChildren={t("yes")}
                                  unCheckedChildren={t("no")}
                                  disabled={
                                    requestingTechDrawingData ||
                                    (disableHasTolerences &&
                                      (!project?.selfCalculation ||
                                        !dataPartForm.numberOfFits))
                                  }
                                />
                              </Form.Item>
                              &nbsp;
                              {requestingTechDrawingData ||
                              techDrawingData?.hasSmallTolerance != null ? (
                                <RobotCalc
                                  loading={requestingTechDrawingData}
                                  completed={
                                    !requestingTechDrawingData &&
                                    techDrawingData?.hasSmallTolerance ==
                                      dataPartForm.hasTolerances
                                  }
                                  error={errorTechDrawingData}
                                />
                              ) : null}
                            </Space.Compact>
                          </div> */}
                          <div className="col col-12 col-lg-4">
                            <Form.Item
                              name="surfaceQuality"
                              label={t("part.surfaceQuality")}
                              rules={[{ required: true }]}
                            >
                              <Select options={surfaceFinishs}></Select>
                            </Form.Item>
                          </div>
                          <div className="col-12 mt-3">
                            <Form.Item
                              label={t("part.form.additionalComments")}
                              name="additionalComments"
                            >
                              <TextArea maxLength={255} />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            )}
            {viewMode !== PartViewMode.EDIT && (
              <>
                <div className="">
                  <Alert
                      className="mb-3"
                      message={t("part.technicalPlastics.infoBox")}
                      type="info"
                      icon={<InfoCircleOutlined />}
                      showIcon
                  />
                  {!checkDesignPrinciple && (
                    <Alert
                      className="mb-3"
                      message={t("part.checkDesignPrinciple")}
                      type="warning"
                      icon={<WarningOutlined />}
                      showIcon
                    />
                  )}
                  <div
                    className={`row mb-3 ${
                      (!referenceCosts || !part?.auto) && "no-price-reference"
                    }`}
                  >
                    <div className="part-data col d-flex flex-wrap">
                      <div className="b-field">
                        <label>{t("material")}:</label>
                        <div className="d-flex">
                          {part?.material?.name}/
                          <MaterialNumber value={part?.material?.number} />
                          {part?.material?.link && <>
                            &nbsp;<a href={part.material.link} target="_blank" rel="noreferrer"><i className="bi bi-box-arrow-up-right"></i></a>
                          </>}
                          &nbsp;
                          {!!techDrawingData?.materialId && (
                            <RobotCalc
                              loading={requestingTechDrawingData}
                              completed={
                                techDrawingData?.materialId === material?.id
                              }
                              error={errorTechDrawingData}
                              message={t("part.robot.material.message")}
                            />
                          )}
                        </div>
                      </div>
                      {isShowCompliance && <>
                        <div className={`b-field`}>
                          <label>{t("part.confirmities")}:</label>
                          <label className="d-table">
                            {!!part?.material?.compliances?.length
                              ? part?.material?.compliances.map((c) => (
                                  <Tag
                                    key={`compliances-${c.id}`}
                                    // color="#FEF2C3"
                                    className="mb-2 app-tag"
                                  >
                                    <span className="text-black">
                                      {c.name}
                                    </span>
                                  </Tag>
                                ))
                              : "None"}
                          </label>
                        </div>
                        <div className={`b-field`}>
                          <label>{t("part.furtherConfirmitiesRequested")}:</label>
                          <label className="d-table">
                            {!!part?.requestedCompliances?.length
                              ? part?.requestedCompliances.map((c) => (
                                  <Tag
                                    key={`compliances-${c.id}`}
                                    // color="#FEF2C3"
                                    className="mb-2 app-tag"
                                  >
                                    <span className="text-black">
                                      {c.name}
                                    </span>
                                  </Tag>
                                ))
                              : "None"}
                          </label>
                        </div>
                      </>}
                      
                      {/* <div className={`b-field`}>
                        <label>{t("part.surfaceTreatments")}:</label>
                        <label className="d-table">
                          {!!part?.surfaceTreatments?.length
                            ? part?.surfaceTreatments.map((s) => (
                                <Tag
                                  key={`surface-${s.id}`}
                                  className="mb-2 app-tag"
                                >
                                  <span className="text-black">
                                    <LocalizationName
                                      localizations={s?.localizations}
                                      valueDefault={s.method}
                                    />
                                  </span>
                                </Tag>
                              ))
                            : "None"}
                        </label>
                      </div> */}

                      <div className="b-field">
                        <label>{t("part.numberOfThreads")}:</label>
                        <div className="d-flex">
                          {(project?.selfCalculation ||
                            techDrawingData?.numOfThreads == null) && (
                            <span className="me-1">
                              {part?.numberOfThreads}
                            </span>
                          )}
                          {techDrawingData?.numOfThreads != null ? (
                            <RobotCalc
                              loading={requestingTechDrawingData}
                              completed={
                                techDrawingData.numOfThreads != null &&
                                techDrawingData.numOfThreads ===
                                  part.numberOfThreads
                              }
                              error={errorTechDrawingData}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="b-field">
                        <label>{t("part.surfaceQuality")}:</label>
                        <label>
                          {t(`surfaceQuality.${part.surfaceQuality}`)}
                        </label>
                      </div>
                      <div className="b-field">
                        <label>{t("part.numberOfFits")}:</label>
                        <div className="d-flex">
                          {(project?.selfCalculation ||
                            techDrawingData?.numOfTolerances == null) && (
                            <span className="me-1">{part?.numberOfFits}</span>
                          )}
                          {techDrawingData?.numOfTolerances != null ? (
                            <RobotCalc
                              loading={requestingTechDrawingData}
                              completed={
                                techDrawingData.numOfTolerances != null &&
                                techDrawingData.numOfTolerances ===
                                  part.numberOfFits
                              }
                              error={errorTechDrawingData}
                            />
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="d-flex flex-column b-field">
                        <label>{t("part.hasTolerances")}:</label>
                        <label className="d-flex">
                          {techDrawingData?.hasSmallTolerance == null && (
                            <>
                              {part?.hasTolerances ? t("yes") : t("no")}
                              &nbsp;
                            </>
                          )}

                          {techDrawingData?.hasSmallTolerance != null ? (
                            <RobotCalc
                              loading={requestingTechDrawingData}
                              completed={
                                techDrawingData?.hasSmallTolerance ===
                                part?.hasTolerances
                              }
                              error={errorTechDrawingData}
                            />
                          ) : null}
                        </label>
                      </div> */}
                    </div>
                    {
                      <div className="ms-auto col-4 quantity-references">
                        <div>
                          {((viewMode === PartViewMode.ADAPT &&
                            project.selfCalculation) ||
                            viewMode === PartViewMode.UPDATED) &&
                            part.auto &&
                            referenceCosts && (
                              <div>
                                <PriceReference
                                  part={part}
                                  data={referenceCosts}
                                  layout="light"
                                  hasUpdateQuantity
                                  onQuantityChanged={onQuantityChanged}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                    }
                  </div>
                  <div>
                    <hr />
                    <div className="row">
                      <div className="col col-12 col-md-6 d-flex flex-column b-field mb-1">
                        <label>
                          {t("part.label.additionalComments")}:
                        </label>
                        <label className="text-break">
                          {part?.additionalComments || (
                            <i className="text-secondary">{t("empty")}</i>
                          )}
                        </label>
                      </div>
                      <div className="col col-12 col-md-4 ms-auto">
                        <div className="row">
                          <div className="col col-12 d-flex align-items-center flex-row part-quantity-input mb-2">
                            <label className="text-label">
                              {t("quantity")}:
                            </label>
                            {(viewMode === PartViewMode.READ_ONLY ||
                              viewMode === PartViewMode.ADAPT ||
                              viewMode === PartViewMode.UPDATED) && (
                              <label
                                className="ms-auto text-end"
                                style={{ width: "70%" }}
                              >
                                {viewMode === PartViewMode.READ_ONLY && (
                                  <>{part?.quantity}</>
                                )}
                                {viewMode !== PartViewMode.READ_ONLY && (
                                  <InputNumberInline
                                    callBack={onFormQuantitySubmit}
                                    value={part?.quantity}
                                    name="quantity"
                                    label={t("quantity")}
                                    inputProps={{
                                      type: "number",
                                      onKeyDown: Utils.triggerTypeNumber,
                                      min: 1,
                                      max: 99999,
                                    }}
                                  />
                                )}
                              </label>
                            )}
                          </div>
                          <div
                            className={`col col-12 d-flex align-items-center mb-2 ${
                              pricePreview && "text-decoration-line-through"
                            }`}
                          >
                            <label className="mb-0 text-label">
                              {t("price.unitPrice")}:
                            </label>
                            {viewMode !== PartViewMode.ADAPT && (
                              <label className="ms-auto mb-0">
                                <CurrencyFormat
                                  value={part?.unitPrice}
                                  adaptPrice={part?.adaptedUnitPrice}
                                  isHideAdapt={
                                    (project?.selfCalculation &&
                                      (!auth.isLogined ||
                                        auth.user?.role === UserRole.BUYER)) ||
                                    (!project?.selfCalculation &&
                                      project?.manual)
                                  }
                                  hiddenValue={
                                    auth.user?.role === UserRole.BUYER &&
                                    !part?.auto &&
                                    (project?.status ===
                                      ProjectStatus.IN_PROGRESS ||
                                      project?.status ===
                                        ProjectStatus.REQUESTED_OFFER)
                                  }
                                  currencyExchange={currencyInfo}
                                />
                              </label>
                            )}
                            {viewMode === PartViewMode.ADAPT && (
                              <PriceAdaptForm
                                ref={adaptForm}
                                part={part}
                                onAdapted={onPriceAdapted}
                                className="app-form"
                              />
                            )}
                          </div>
                          {(pricePreviewLoading || pricePreview) && (
                            <div className="col col-12 d-flex align-items-center mb-2 text-primary">
                              <label className="mb-0 text-label">
                                {t("price.preview")}:
                              </label>
                              <label className="ms-auto mb-0 fw-bold">
                                {pricePreviewLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <CurrencyFormat value={pricePreview} currencyExchange={currencyInfo}/>
                                )}
                              </label>
                            </div>
                          )}
                          <div
                            className={`col col-12 d-flex align-items-center mb-1 ${
                              pricePreview && "text-decoration-line-through"
                            }`}
                          >
                            <label className="mb-0 text-label">
                              {t("price.netTotal")}:
                            </label>
                            <h6 className="ms-auto mb-0">
                              <CurrencyFormat
                                value={part?.unitPrice * part?.quantity}
                                adaptPrice={
                                  part?.adaptedUnitPrice != null
                                    ? part.totalPrice
                                    : undefined
                                }
                                isHideAdapt={
                                  part?.unitPrice == null ||
                                  (project?.selfCalculation &&
                                    (!auth.isLogined ||
                                      auth.user?.role === UserRole.BUYER)) ||
                                  (!project?.selfCalculation && project?.manual)
                                }
                                hiddenValue={
                                  (part?.auto && part?.unitPrice == null) ||
                                  (auth.user?.role === UserRole.BUYER &&
                                    !part?.auto &&
                                    (project?.status ===
                                      ProjectStatus.IN_PROGRESS ||
                                      project?.status ===
                                        ProjectStatus.REQUESTED_OFFER))
                                }
                                currencyExchange={currencyInfo}
                              />
                            </h6>
                          </div>
                          {(pricePreviewLoading || pricePreview) && (
                            <div className="col col-12 d-flex align-items-center mb-2 text-primary mb-0">
                              <label className="mb-0 text-label">
                                {t("price.netTotal")}:
                              </label>
                              <h6 className="ms-auto mb-0 fw-bold">
                                {pricePreviewLoading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <CurrencyFormat
                                    value={(pricePreview || 0) * part.quantity}
                                    currencyExchange={currencyInfo}
                                  />
                                )}
                              </h6>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-part d-flex"></div>
              </>
            )}
          </div>
        </div>
      </div>
      <CadFileItemDeleteModal
        part={part}
        file={file}
        onDeletedCallBack={onDeletedCallBack}
        onCanceledCallBack={setOpenDelete.bind(null, false)}
        showDeleteModalProp={openDelete}
      />
      <Modal
        open={isProcessingReport}
        title={t("project.processingReport")}
        width={"70vw"}
        className="modal-body-scroll"
        onCancel={setIsProcessingReport.bind(null, false)}
        footer={null}
      >
        <ProcessingReport partId={part?.id} />
      </Modal>
    </>
  );
}

export default CadFileItemTechnicalPlastics;
