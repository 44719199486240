import { Modal, UploadFile } from "antd";
import { Part } from "app/models/Part";
import { projectActions } from "app/redux/slides/project.slide";
import partServices from "app/services/part.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface DefaultProps {
  part?: Part;
  onDeletedCallBack: any;
  onCanceledCallBack?: any;
  showDeleteModalProp: boolean;
}

function AssemblyGroupDeleteModal(props: DefaultProps) {
  const { part, onDeletedCallBack, showDeleteModalProp, onCanceledCallBack } =
    props;
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setOpenDelete(showDeleteModalProp);
  }, [showDeleteModalProp]);

  const cancelDeleteModal = () => {
    setOpenDelete(false);
    if (onCanceledCallBack) {
      onCanceledCallBack();
    }
  };

  const onDeletePart = async () => {
    setDeleting(true);
    try {
      if (part && part?.parentFileStoreKey) {
        await partServices.deletePartByParentFile({
          parentKey: part?.parentFileStoreKey,
        });
        dispatch(
          projectActions.deletePartByParentFile(part?.parentFileStoreKey)
        );
        setOpenDelete(false);
        onDeletedCallBack(part);
      }
    } catch (error) {}
    setDeleting(false);
  };

  return (
    <Modal
      open={openDelete}
      title={`${part?.parentFileName}`}
      onCancel={cancelDeleteModal}
      onOk={onDeletePart}
      cancelButtonProps={{
        disabled: deleting,
      }}
      okButtonProps={{
        loading: deleting,
      }}
    >
      {t("part.delete.title", {
        name: part?.parentFileName,
      })}
    </Modal>
  );
}

export default AssemblyGroupDeleteModal;
