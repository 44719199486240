import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListViewMode, Project } from "../../models/Project";
import { projectSelector } from "../../redux/slides/project.slide";
import ExportSheetButton from "../Button/ExportSheetButton";
import ListParts from "./ListParts";

interface DefaultProps {
  onCheckOut?: any;
}

function CalculationStep3(props: DefaultProps) {
  const { onCheckOut } = props;
  const { data } = useSelector(projectSelector);
  const [project, setProject] = useState<Project>(data);
  useEffect(() => {
    setProject(data);
  }, [data]);

  const onSubmitCheckOut = () => {
    if (onCheckOut) onCheckOut();
  };

  if (!project) return <></>;
  return (
    <>
      <ListParts
        project={project}
        mode={ListViewMode.EDIT}
        onCheckOut={onSubmitCheckOut}
      />
    </>
  );
}

export default CalculationStep3;
