import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { Part } from "app/models/Part";
import { UserRole } from "app/models/UserRole";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface DefaultProps {
  part: Part;
}

function AlertManualReason(props: DefaultProps) {
  const { part } = props;
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const [manualReason, setManualReason] = useState<any>("");
  useEffect(() => {
    if (part && part.manualReason) {
      if(part.manualReason.indexOf("CALC_ERR") > -1) {
        setManualReason("CALC_ERR");
      } else if(part.manualReason.indexOf("ANALYZE_ERR") > -1) {
        setManualReason("ANALYZE_EXCEPTION");
      } else {
        setManualReason(part.manualReason);
      }
    }
  }, [part]);
  if (!part || part?.auto) return <></>;

  const keyMessage = auth.user?.role == UserRole.BUYER ? UserRole.BUYER : UserRole.SELLER;
  return (
    <Alert
      className="mb-3"
      message={
        <>
          {auth.user?.role === UserRole.BUYER
            ? t("project.manualNote")
            : t("project.manual.seller.part.note")}
          {t(`part.manual.${keyMessage}.${manualReason}`) &&
            t(`part.manual.${keyMessage}.${manualReason}`, {
              defaultValue: "",
            }) && (
              <>
                <br />
                {t("reason")}:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `part.manual.${keyMessage}.${manualReason}`,
                      {
                        email: config.supportMail,
                      }
                    ),
                  }}
                ></span>
              </>
            )}
        </>
      }
      type="info"
      icon={<InfoCircleOutlined />}
      showIcon
    />
  );
}

export default AlertManualReason;
