import { WarningOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { Part } from "app/models/Part";
import { useTranslation } from "react-i18next";

interface DefaultProps {
  part: Part;
}

function AlertPartWarning(props: DefaultProps) {
  const { part } = props;
  const warnings = part?.warnings;
  const { t } = useTranslation();

  if (!part || !warnings || !warnings.length) return <></>;

  return (
    <Alert
      className="mb-3 ant-alert-center"
      style={{alignItems: "center"}}
      message={
        <>
          {warnings.map(w => <div key={`part.warning.${w}`}>
            {t(`part.warning.${w}`)}
          </div>)}
        </>
      }
      type="warning"
      icon={<WarningOutlined />}
      showIcon
    />
  );
}

export default AlertPartWarning;
