import { Modal, Skeleton, UploadFile } from "antd";
import { CuttingType } from "app/models/CuttingType";
import { Part, PartManualReason, PartPreview } from "app/models/Part";
import { ShopMaterial } from "app/models/ShopMaterial";
import { ShopType } from "app/models/ShopType";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import partServices from "app/services/part.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NumberFormat from "../../Format/NumberFormat";
import CadFileIcon from "../../SVGs/CadFileIcon";
import PdfFileIcon from "../../SVGs/PdfFileIcon";
import SheetMetalUnfold from "../../SVGs/SheetMetalUnfold";
import PartImage from "../PartImage";

interface DefaultProps {
  partPreview?: PartPreview;
  part?: Part;
  uploading: any;
  cuttingType: CuttingType;
  file?: UploadFile<any>;
}

function CadFileItemInfo(props: DefaultProps) {
  const { partPreview, part, uploading, cuttingType, file } = props;
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const [isOpenUnfoldingPreview, setIsOpenUnfoldingPreview] = useState(false);
  const { t } = useTranslation();
  const showCuttingType =
    config.shopType === ShopType.BOTH &&
    config.shopMaterial != ShopMaterial.PLASTIC &&
    config.shopMaterial != ShopMaterial.RUBBER;
  const downloadCadFile = async () => {
    if (part) {
      try {
        await partServices.downloadCadFile({
          id: part.id,
          name: part.partFileName,
        });
      } catch (error) {}
    }
  };
  if (part && part.manualReason == PartManualReason.NO_CAD_FILE) return <></>;
  return (
    <>
      <div className="part-b-left col-auto d-flex flex-column align-items-start px-4">
        {uploading && !part && !partPreview && (
          <Skeleton.Image
            active={true}
            style={{ width: "100%", height: "12rem" }}
          />
        )}

        {part && (
          <>
            {(part || partPreview) && (
              <PartImage
                wrapperStyle={{ fontSize: "3rem" }}
                src={
                  part?.preview2dUrl || part?.dxfFileUrl || part?.previewPngUrl
                }
                partPreview={partPreview}
                cuttingtype={cuttingType}
                part={part}
                width={"100%"}
                height={"12rem"}
                showPartName={true}
                readonly={auth.user?.sub !== part?.createdBy?.email}
              />
            )}
            {part && (
              <>
                {part.length && (
                  <p className="mt-2 mb-0">
                    <span className="text-label">{t("length")}:</span>{" "}
                    <NumberFormat
                      value={part.length}
                      maximumFractionDigits={2}
                    />{" "}
                    mm
                  </p>
                )}
                {part.height && (
                  <p className="mb-0">
                    <span className="text-label">{t("height")}:</span>{" "}
                    <NumberFormat
                      value={part.height}
                      maximumFractionDigits={2}
                    />{" "}
                    mm
                  </p>
                )}
                {part.cuttingType !== CuttingType.MILLING_AND_TURNING && (
                  <p className="mb-0">
                    <span className="text-label">
                      {t("part.form.thickness")}:
                    </span>{" "}
                    {part.thickness ?? "--"} mm
                  </p>
                )}
                {part.cuttingType == CuttingType.SHEET_METAL_CONFIGURE &&
                  part.numberOfHoles != null &&
                  part.numberOfHoles > 0 && (
                    <p className="mb-0">
                      <span className="text-label">
                        {t("customDxf.numberOfHole")}:
                      </span>{" "}
                      {part.numberOfHoles}
                    </p>
                  )}
              </>
            )}
            {part?.previewUnfoldingUrl && (
              <a
                className="text-decoration-none mt-2"
                onClick={setIsOpenUnfoldingPreview.bind(null, true)}
              >
                <SheetMetalUnfold />
                &nbsp;{t("part.unfoldingPreview")}
              </a>
            )}
            {!part.parentFileStoreKey && (
              <label className="mt-2 mb-2 fw-bold">
                <a
                  className="text-decoration-none"
                  title={part?.partFileName || file?.name}
                  target="_blank"
                  rel="noreferrer"
                  onClick={downloadCadFile}
                >
                  <CadFileIcon /> {part?.partFileName || file?.name}
                </a>
              </label>
            )}
            {part && part.pdfFileName && (
              <label className="mb-2">
                <a
                  className="text-decoration-none"
                  href={part.technicalDrawingUrl}
                  title={part.pdfFileName}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PdfFileIcon /> {part.pdfFileName}
                </a>
              </label>
            )}
            {config.shopType === ShopType.BOTH && (
              <p className="mt-1 mb-0">
                <label className="ms-auto">
                  {t(`cuttingType.${part?.cuttingType || cuttingType}`)}
                </label>
              </p>
            )}
            {part?.customShapeType &&
              part.numberOfHoles != null &&
              part.numberOfHoles > 0 && (
                <p className="mb-0">
                  <span className="text-label">
                    {t("customDxf.numberOfHole")}:
                  </span>{" "}
                  {part.numberOfHoles}
                </p>
              )}
          </>
        )}
      </div>
      <Modal
        open={isOpenUnfoldingPreview}
        width={"calc(60vw - 46px)"}
        onCancel={setIsOpenUnfoldingPreview.bind(null, false)}
        footer={null}
        centered
        className="unfolding-preview"
      >
        <div className="d-flex justify-content-center align-items-center">
          <img src={part?.previewUnfoldingUrl} />
        </div>
      </Modal>
    </>
  );
}

export default CadFileItemInfo;
