import { configActions, configSelector } from "app/redux/slides/config.slide";
import configServices from "app/services/configServices";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "app/constants";

const capitalise = (input: any) => {
  return `${input[0].toUpperCase()}${input.slice(1).toLowerCase()}`;
};
const getTitleSite = () => {
  let key = "dev";
  if (
    process.env.REACT_APP_API_URL &&
    process.env.REACT_APP_API_URL?.indexOf("localhost") < 0
  ) {
    key = process.env.REACT_APP_API_URL.replace("https://api.", "").replace(
      ".gocad.de",
      ""
    );
  }
  switch (key) {
    case "ensinger":
      return "TECAPART | Ensinger";
    case "osl":
      return "OSL quotation";
    case "lst247":
      return "LST247 Shop";
    case "dev":
      return "Devbu Shop";
    default:
      return `${capitalise(key)} Shop`;
  }
};
function AppBrowserRouter(props: any) {
  const { t } = useTranslation();
  document.title = getTitleSite();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);

  const getConfig = async () => {
    try {
      const rs = await configServices.getData();
      dispatch(configActions.setData(rs));
    } catch (error) {}
  };
  useEffect(() => {
    getConfig();
  }, []);
  useEffect(() => {
    const currentURL = window.location.href;
    if (
      config.subfolderFrontendUrl &&
      currentURL.indexOf(process.env.PUBLIC_URL) == -1
    ) {
      window.location.href = currentURL + config.subfolderFrontendUrl;
    }
  }, [config]);
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {props.children}
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
export default AppBrowserRouter;
