import { Shape } from "three";
import { Compliance } from "./Compliance";
import { CuttingType } from "./CuttingType";
import { DeburringType } from "./DeburringType";
import { LaserGas } from "./LaserGas";
import { Material } from "./Material";
import { ModelAnalysis } from "./ModelAnalysis";
import { RollingDirection } from "./RollingDirection";
import { SurfaceTreatment } from "./SurfaceTreatment";
import { TechDrawingData } from "./TechDrawingData";
import { User } from "./User";
import { ShapeTypes } from "app/components/Shape/ShapeConstants";

export interface Part {
  id?: number;
  modelAnalysis?: ModelAnalysis;
  calculation?: Calculation;
  price?: number;
  projectId?: number;
  cuttingType: CuttingType;
  quantity: number | 1;
  additionalComments?: null;
  materialId?: number;
  materialGroupId?: number;
  material?: Material;
  numberOfFits?: number | 0;
  numberOfThreads?: number | 0;
  materialProvided?: boolean;
  deburring?: boolean;
  rollingDirection?: RollingDirection;
  countersink?: number;
  threadCutting?: number;
  cuttingTime?: number;
  preCutLength?: number;
  preCutWidth?: number;
  totalPrice: number | 0;
  unitPrice: number | 0;
  adaptedUnitPrice?: number;
  priceAdapted?: boolean;
  numCalc?: number;
  hasTolerances?: boolean;
  surfaceQuality?: number;
  noMaterialSelected?: boolean;
  surfaceTreatmentIds?: number[];
  surfaceTreatments?: SurfaceTreatment[];
  preview2dUrl?: string;
  previewSvgUrl?: string;
  previewPngUrl?: string;
  previewProcessedUrl?: string;
  auto?: boolean;
  partFileName?: string;
  pdfFileName?: string;
  partFilePath?: string;
  cadFileUrl?: string;
  dxfFileUrl?: string;
  technicalDrawingUrl?: string;
  previewUnfoldingUrl?: string;
  dxfUnfoldingUrl?: string;
  manualReason?: any;
  approved?: boolean;
  techDrawingData?: TechDrawingData;
  thickness?: number;
  laserGas?: LaserGas;
  dxfLayers?: any;
  name?: string;
  length?: number;
  width?: number;
  height?: number;
  createdBy?: User;
  nbFolds?: number;
  deburringType?: DeburringType;
  laserMarking?: boolean;
  requestedCompliances?: Compliance[];
  numberOfHoles?: number;
  warnings?: string[];
  parentFileStoreKey?: string;
  parentFileName?: string;
  parentPreviewUrl?: string;
  fileUUID?: string;
  customShapeType?: ShapeTypes;
  isParent: boolean;
}

export interface Calculation {
  id?: number;
  createdBy?: User;
  modelMass?: number;
  blankMass?: number;
  emission?: number;
}

export interface PartPreview { 
  previewSvgUrl?: string;
  previewPngUrl?: string;
}

export enum PartManualReason {
  NO_CAD_FILE = "NO_CAD_FILE"
}