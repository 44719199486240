import { useTranslation } from "react-i18next";

interface DefaultProps {
  value?: number;
  maximumFractionDigits?: number;
}
const NumberFormat = (props: DefaultProps) => {
  const { t } = useTranslation();
  const { value, maximumFractionDigits = 5 } = props;
  if (value === null || value === undefined) return <>--</>;

  return (
    <>
      {new Intl.NumberFormat("de-DE", {
        style: "decimal",
        maximumFractionDigits: maximumFractionDigits
      }).format(value)}
    </>
  );
};

export default NumberFormat;
