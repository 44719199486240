/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from "@ant-design/icons";
import { Upload, UploadProps } from "antd";
import { CuttingType } from "app/models/CuttingType";
import { Part, PartManualReason } from "app/models/Part";
import partServices from "app/services/part.service";
import { HTMLAttributes, useEffect, useState } from "react";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "app/redux/slides/project.slide";
import LoadingIcon from "../SVGs/LoadingIcon";
import { configSelector } from "app/redux/slides/config.slide";
import { ShopType } from "app/models/ShopType";
import { useTranslation } from "react-i18next";

interface DefaultProps extends HTMLAttributes<any> {
  part: Part;
  onCadUploaded?: any;
}

function PartCadUpload(props: DefaultProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  const { onCadUploaded, part } = props;
  const { cuttingType } = part;
  const [uploading, setUploading] = useState(false);
  if (part && part.manualReason != PartManualReason.NO_CAD_FILE) return <></>;

  const uploadProps: UploadProps = {
    beforeUpload: (file, fileList) => {
      uploadCadFile(file);
      return false;
    },
    name: "file",
    multiple: false,
    accept: `.stp, .step, .jt, .pvz, .edz, .prt, .asm, .sldasm, .sldprt, .model, .catpart, .catproduct, .3ds, .prj, .par, .asm, .psm, .sldprt, .sldasm, .prt, ${
      cuttingType === CuttingType.SHEET_METAL ? " .dxf, .dwg," : ""
    } ${cuttingType === CuttingType.MILLING_AND_TURNING ? " .ipt," : ""}`,
    disabled: uploading,
  };

  const uploadCadFile = async (file: any) => {
    setUploading(true);
    try {
      const rs = await partServices.uploadCad({
        id: part.id,
        cuttingType: part.cuttingType,
        file,
      });
      dispatch(projectActions.setPart(rs));
      if (onCadUploaded) onCadUploaded(rs);
    } catch (error) {}
    setUploading(false);
  };
  return (
    <div className="part-b-left part-upload-cad col-auto d-flex flex-column">
      <Upload {...uploadProps} showUploadList={false} multiple={false}>
        <div className={`box-upload ${uploading && "cad-uploading"}`}>
          {uploading && <LoadingIcon width={"2rem"} />}
          {!uploading && (
            <>
              <UploadOutlined />
              <p className="m-0">Upload cad file</p>
            </>
          )}
        </div>
      </Upload>
      {part && part.pdfFileName && (
        <label className="mb-2">
          <a
            className="text-decoration-none"
            href={part.technicalDrawingUrl}
            title={part.pdfFileName}
            target="_blank"
            rel="noreferrer"
          >
            <PdfFileIcon /> {part.pdfFileName}
          </a>
        </label>
      )}
      {config.shopType === ShopType.BOTH && (
        <p className="mt-1 mb-0">
          <label className="ms-auto">
            {t(`cuttingType.${part?.cuttingType || cuttingType}`)}
          </label>
        </p>
      )}
    </div>
  );
}

export default PartCadUpload;
