import { UserRole } from "app/models/UserRole";

export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_ENV === "production"
    ? "AIzaSyCpgg0QnlbThuJlawLoEc4PcwzUdCu2NgU"
    : "AIzaSyDh0j-XhE_I653QOXZwcEfqrrJ8QvzqUuo";
export const GOOGLE_CLIENT_ID =
  "472481356401-p1ra1eukae84irof6tra9702hr5r3mi6.apps.googleusercontent.com";
export const MANAGER_ROLES = [
  UserRole.ADMIN,
  UserRole.ORDER_MANAGER,
  UserRole.SELLER,
];
